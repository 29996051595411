$(document).ready(function() {
  /**
   * @description - Tabs functionality
   */
  measureTabs();

  function measureTabs() {
    $('.fil-tabs')
      .each(function() {
        // get this instance of the tabs component
        let thisTabsInstance = $(this);

        // collect the tabPanels of this instance
        let tabPanels = thisTabsInstance.find('.tabs-panel');

        for (let i = tabPanels.length - 1; i >= 0; i--) {
          let thisPanel = tabPanels.eq(i);
          thisPanel.css({ display: 'block', 'transition-duration': '0' });
          let thisHeight = thisPanel.innerHeight();
          thisPanel.attr('data-height', thisHeight);
        }
      })
      .on('paginator:results-height-changed', function(event) {
        const $thisPanel = $(event.target).closest('.tabs-panel.is-active');
        if ($thisPanel.length) {
          const thisHeight = $thisPanel.innerHeight();
          $thisPanel.attr('data-height', thisHeight);
          $thisPanel.parent().css('height', thisHeight);
        }
      });
    activateTab();
  }

  function activateTab() {
    $('.tabs-content').each(function(i, element) {
      const thisHeight = `${$(this)
        .find('.tabs-panel.is-active')
        .attr('data-height')}px`;
      if ($(element).hasClass('changeable_height')) {
        $(this).css('height', 'auto');
      } else {
        $(this).css('height', thisHeight);
      }
    });
  }

  if ($('.fil-tabs').length > 0) {
    //now we load the main.css asynchronisly,
    //we need to wait for main.css finish loading, and then init the tabs.
    if (window.mainCSSLoader) {
      window.mainCSSLoader.addEventListener('load', function() {
        measureTabs();
      });
    }

    $('.fil-tabs .tabs-title.true-tab a').click(function(event) {
      event.preventDefault();
      let thisIndex = $(this)
        .parent()
        .index();
      let thisContentPanel = $(this)
        .closest('.fil-tabs')
        .find('.tabs-content');
      let currentPanel = thisContentPanel.find('.tabs-panel.is-active');
      let currentHeight = currentPanel.attr('data-height');
      let newPanel = thisContentPanel.find('.tabs-panel').eq(thisIndex);
      let newHeight = newPanel.attr('data-height');
      let scaleOfChange = newHeight / currentHeight;

      if (newHeight !== currentHeight) {
        // have to use transform shorthand ( scale( 1 ) ) to reset scale for IE11 as it will not accept scale( 1,1 )
        (function() {
          thisContentPanel
            .css({
              // height: newHeight,
              transform: `scale( 1,${scaleOfChange})`,
              'transition-duration': '0s'
            })
            .promise()
            .done(function() {
              thisContentPanel.css({
                transform: 'scale( 1 )',
                'transition-duration': '0.3s',
                'transition-delay': '0.1s'
              });
            });
        })();
      }
    });
  }

  let resizeRaFTick = false;

  function windowResizingEvent() {
    requestRaFTick();
  }

  function requestRaFTick() {
    if (!resizeRaFTick) {
      requestAnimationFrame(update);
    }
    resizeRaFTick = true;
  }

  function update() {
    // reset the tick so we can
    // capture the next onScroll
    resizeRaFTick = false;

    // do what you want in debouced style
    measureTabs();
  }

  $(window).resize(windowResizingEvent);

  (function($, sr) {
    // debouncing function from John Hann
    // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
    let debounce = function(func, threshold, execAsap) {
      let timeout;

      return function debounced() {
        let obj = this,
          args = arguments;

        function delayed() {
          if (!execAsap) {
            func.apply(obj, args);
          }
          timeout = null;
        }

        if (timeout) {
          clearTimeout(timeout);
        } else if (execAsap) {
          func.apply(obj, args);
        }

        timeout = setTimeout(delayed, threshold || 100);
      };
    };
    // smartresize
    jQuery.fn[sr] = function(fn) {
      return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
    };
  })(jQuery, 'smartresize');
});
