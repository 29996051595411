import { watchListConfigKeys } from './watchListConfig';
const secureToken = atob(sessionStorage.getItem('dGlk'));
const deviceDNA = JSON.parse(sessionStorage.getItem('deviceMFP'));

const userWlist = [];

export function userWlistNewArray() {
  return [...userWlist];
}

export function getWatchlist(watchListAPI) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: watchListAPI,
      type: 'GET',
      dataType: 'json',
      headers: {
        tid: secureToken,
        machineFingerPrint: deviceDNA
      },
      cache: false,
      success: function(responseList) {
        if (responseList.length) {
          $.each(responseList, function(i, val) {
            if (null != val.isin && $.inArray(val.isin, userWlist) == -1) {
              userWlist.push(val.isin);
            }
          });
        }

        resolve(userWlist);
      },
      error: function(textStatus, errorThrown) {
        reject(new Error(`Failed with status Code ${textStatus.statusText} and ${errorThrown}`));
      }
    });
  });
}

$(document).ready(function() {
  $('.search-result-container').on('click', '.search-result__watchlist-link', function(e) {
    $('.dialog-overlay').remove();
    addToWatchlist(
      e.target.attributes.isin.nodeValue,
      e.target.attributes.sedol.nodeValue,
      e.target.attributes.fundName.nodeValue
    );
  });

  function addToWatchlist(isin, sedol, fundName) {
    let href = watchListConfigKeys.WatchlistAjaxURL;
    let strWatchListDetails = [
      {
        fundCode: null,
        isin: isin,
        sedol: sedol
      }
    ];
    $.ajax({
      url: href,
      type: 'POST',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        watchListDetails: strWatchListDetails
      }),
      headers: {
        tid: secureToken,
        machineFingerPrint: deviceDNA
      },
      cache: false,
      success: function(data) {
        if (data == true) {
          closeValidation();
          watchlistAnalytics(fundName, isin);
          const $selectedAsset = $(`#wlist${isin}`);
          if ($selectedAsset.length) {
            $selectedAsset.text(watchListConfigKeys.addedLabel);
            $selectedAsset.attr('href', watchListConfigKeys.addedLink);
            $selectedAsset.addClass('arrow-right-cta');

            /** TODO: This section is manually updating the watchlist link and its Attributes
             Need to change these later **/

            watchListConfirmation(
              watchListConfigKeys.heading,
              watchListConfigKeys.messageBodyHeading,
              watchListConfigKeys.messageBodyContent,
              watchListConfigKeys.headingIconAddition,
              watchListConfigKeys.watchlistAddButton
            );
            userWlist.push(isin);
          }
        } else {
          showError(data, isin);
        }
      },
      error: function(textStatus, errorThrown) {
        console.log(`Failed with status Code ${textStatus.statusText} and ${errorThrown}`);
        watchListConfirmation(
          watchListConfigKeys.watchListErrorHeading,
          watchListConfigKeys.watchListBodyErrorHeading,
          '',
          watchListConfigKeys.headingIconWarning,
          ''
        );
      }
    });
  }

  function watchlistAnalytics(fundname, fundisin) {
    document.dispatchEvent(
      new CustomEvent('analytics', {
        detail: {
          type: 'event',
          action: 'pi.global.addToWatchList',
          productName: fundname,
          productISIN: fundisin
        }
      })
    );
  }

  function showError(data) {
    let errorCode = null != data.errors[0].code ? data.errors[0].code : '';
    if (errorCode == 'err.fund.count.exceeds') {
      closeValidation();
      watchListConfirmation(
        watchListConfigKeys.watchListFullHeading,
        watchListConfigKeys.watchListFullMessageBodyHeading,
        '',
        watchListConfigKeys.headingIconWarning,
        watchListConfigKeys.watchlistAddButton
      );
    } else if (errorCode == 'err.fund.already.saved') {
      watchListConfirmation(watchListConfigKeys.alreadyAddedFundHeading, '', '', '', '');
    } else {
      watchListConfirmation(
        watchListConfigKeys.watchListErrorHeading,
        watchListConfigKeys.watchListBodyErrorHeading,
        '',
        watchListConfigKeys.headingIconWarning,
        ''
      );
    }
  }

  function closeValidation() {
    $('li[name*="wlistError"]').remove();
  }

  function watchListConfirmation(
    heading,
    messageBodyHeading,
    messageBodyContent,
    headingIcon,
    watchlistAddButton
  ) {
    let content = `
      <div class="dialog-overlay">
        <div class="dialog">
          <div class="icon cross-button">
            <span class="fil-icon icon-only fil-icon-cross-regular closeConfirmPop" />
          </div>
          <div class="content-wrap-styler">
            <header class="confirm-header">
              <h1 class="watchlist-heading">
                ${headingIcon}${heading}
              </h1>
            </header>
            <div class="messageWrapper">
              <div class="dialog-msg">
                ${messageBodyHeading}${messageBodyContent}
              </div>
              <footer> ${watchlistAddButton}</footer>
            </div>
          </div>
        </div>
      </div>
    `;

    $('body').prepend(content);
    $('.viewYourWatchList').click(function() {
      $(this)
        .parents('.dialog-overlay')
        .hide(500, function() {
          $(this).remove();
        });
    });

    $('.cancelAction, .closeConfirmPop').click(function() {
      $(this)
        .parents('.dialog-overlay')
        .show(500, function() {
          $(this).remove();
        });
    });
  }
});
