const SharecastArticlePaginator = require('./sharecast_paginator.js');
require('./sharecast-detail.js');
document.addEventListener('DOMContentLoaded', () => {
  const $searchPaginator = $('[data-paginator="sharecastarticles"]');
  let theSearch;

  if ($searchPaginator.length) {
    theSearch = new SharecastArticlePaginator($searchPaginator, false);
    theSearch.rebuild();
  }
});
