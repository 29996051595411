import URL from 'core-js/web/url';

let FidelityForm = {
  f: null, // Referenz zum form-tag (jQuery)
  fieldsToValidate: null,

  config: {
    envDev:
      window.location.href.indexOf('http') !== 0 ||
      window.location.href.indexOf('bippesbrandao.net') !== -1 ||
      window.location.href.indexOf('bippesbrandao.de') !== -1 ||
      window.location.href.indexOf('localhost') !== -1,
    debug: false,
    redirectionUrl: $('#redUrl').val(),
    dankeSuffix: '-danke.html',
    subject: window.unescape('Anfrage R%FCckruf-Service Fidelity Direkt'),
    subjectUrgent: window.unescape('DRINGEND: '),
    mail: 'info@fidelity.de',
    debugMail: 'info@fidelity.de',
    ajaxMode: true
  },

  init: function() {
    if (this.config.envDev) {
      this.config.debug = true;
    }

    this.f = jQuery('#callback_form');
    this.f.submit(jQuery.proxy(this, 'checkAndSubmit'));
    this.fieldsToValidate = this.f.find(
      'div.error > input, div.error > .checkbox-holder > input, div.error > select'
    );
    this.fieldsToValidate.focus(jQuery.proxy(this, 'hideError'));
    this.fillOptionDatum();
    this.fillOptionZeit();
  },

  /**
   * Choose the appropriate API endpoints based on the URL
   *
   * This endpoint is maintained in de-secure-backend
   * @see https://bitbucket.bip.uk.fid-intl.com/projects/UKWD/repos/de-secure-backend/browse/modules/lambdas/public-forms/src/controllers.js
   *
   * @param   {string} urlString URL of the current page
   * @returns {string} API endpoint
   */
  getApiUrl: function(urlString) {
    const url = new URL(urlString);
    let apiBase;

    switch (url.hostname) {
      case 'localhost':
      case '127.0.0.1':
        apiBase = `${url.hostname}:3000`;
        break;
      case 'directnp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'fidelitynp.fidelity.de':
        apiBase = 'xapi.directnp.fidelity.de';
        break;
      case 'direct.fidelity.de':
      default:
        apiBase = 'xapi.direct.fidelity.de';
        break;
    }

    return `${url.protocol}//${apiBase}/api/public/forms/callback`;
  },

  fillOptionDatum: function() {
    this.formDatum = jQuery('#datum');
    let today = new Date();
    let tag = '';
    let daten = [];
    let i;
    let wochentag = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag'
    ];

    // Feiertage 2017-2018
    let feiertag = [
      '25.05.2017',
      '05.06.2017',
      '15.06.2017',
      '03.10.2017',
      '31.10.2017',
      '25.12.2017',
      '26.12.2017',
      '01.01.2018'
    ];

    // 14 Tage ab heute
    let lastday = new Date(today.getTime() + 1296000000);

    let stundenzahl = today.getTime() - today.setHours(0, 0, 0);
    // bis 17:30 liefert true zurück
    let callToday = stundenzahl < 63000000 && today.getDay() !== 0 && today.getDay() !== 6;

    if (!callToday) {
      today = new Date(today.getTime() + 86400000);
    }

    while (today.getDate() !== lastday.getDate()) {
      tag = `${(today.getDate() + 100).toString().substr(1)}.${(today.getMonth() + 101)
        .toString()
        .substr(1)}.${today.getFullYear()}`;
      if (today.getDay() !== 0 && today.getDay() !== 6 && jQuery.inArray(tag, feiertag) === -1) {
        daten.push(`${wochentag[today.getDay()]},  ${tag}`);
      }
      today = new Date(today.getTime() + 86400000);
    }
    for (i = 0; i < daten.length; i++) {
      jQuery('<option/>')
        .attr('value', daten[i])
        .text(daten[i])
        .appendTo(this.formDatum);
    }
    jQuery('#datum').change(jQuery.proxy(this.fillOptionZeit, this));
  },

  fillOptionZeit: function() {
    this.formZeit = jQuery('#uhrzeit');
    let datumSelected = jQuery('#datum :selected');
    let today = new Date();
    let daten = [];
    let stunde = today.getHours();
    let minute = today.getMinutes();
    let stundenzahl = today.getTime() - today.setHours(0, 0, 0);
    let i;
    // bis 17:30 liefert true zurück
    let callToday =
      stundenzahl > 28800000 &&
      stundenzahl < 63000000 &&
      today.getDay() !== 0 &&
      today.getDay() !== 6;

    // Ab 31.te Minute wird die Stunde hochgezählt
    if (minute > 30) {
      stunde += 1;
    }
    if (datumSelected.index() !== 0 || !callToday) {
      this.formZeit.html('');
      for (i = 8; i <= 17; i++) {
        daten.push(`zwischen ${i}:00 und ${i + 1}:00 Uhr`);
      }
    } else {
      this.formZeit.html('');
      daten.push(window.unescape('sofort (in den n%E4chsten 30 Min.)'));
      for (i = stunde + 1; i <= 17; i++) {
        daten.push(`zwischen ${i}:00 und ${i + 1}:00 Uhr`);
      }
    }
    for (i = 0; i < daten.length; i++) {
      jQuery('<option/>')
        .attr('value', daten[i])
        .text(daten[i])
        .appendTo(this.formZeit);
    }
  },

  checkAndSubmit: function(e) {
    e.preventDefault();
    const actionUrl = this.getApiUrl(window.location.href);
    let hasErrors = false;
    let i, field;
    let isvalide = false;

    for (i = 0; i < this.fieldsToValidate.length; i++) {
      field = jQuery(this.fieldsToValidate[i]);
      if (field.attr('type') === 'checkbox') {
        isvalide = field.is(':checked');
      } else {
        isvalide = field.val() ? true : false;
      }

      if (!isvalide) {
        hasErrors = true;
        this.showError(field);
      }
    }

    if (!hasErrors) {
      // daten fürs abschicken vorbereiten
      if (
        jQuery('#uhrzeit')
          .val()
          .indexOf('sofort') >= 0
      ) {
        FidelityForm.config.subject =
          FidelityForm.config.subjectUrgent + FidelityForm.config.subject;
      }
      let submitData = {
        anrede: jQuery('#anrede').val(),
        vorname: jQuery('#firstname').val(),
        name: jQuery('#surname').val(),
        telefon: jQuery('#tel').val(),
        thema: jQuery('#info').val(),
        datum: jQuery('#datum').val(),
        uhrzeit: jQuery('#uhrzeit').val(),
        datenschutz: jQuery('#checkboxConfirm').is(':checked') ? 'ja' : 'nein'
      };

      if (FidelityForm.config.ajaxMode) {
        jQuery.ajax({
          type: 'POST',
          url: actionUrl,
          data: this.encodeData(submitData),
          success: function() {
            FidelityForm.afterSubmit();
          }
        });
      } else {
        // Schattenformular bauen, das die Daten in die richtige Reihenfolge bringt und dann abschickt.
        let hiddenFormContainer = jQuery('#hiddenFormContainer');
        if (!hiddenFormContainer.length) {
          hiddenFormContainer = jQuery('<div id="hiddenFormContainer"></div>').appendTo(
            document.body
          );
        }

        let c = `<form methode="post" action="${actionUrl}" accept-charset="ISO-8859-15">`;
        jQuery.each(
          submitData,
          jQuery.proxy(function(k, v) {
            c += this.getFormValue(k, v);
          }, this)
        );
        c += '<form>';

        hiddenFormContainer.html(c);
        hiddenFormContainer.find('form').submit();
      }
    }
  },

  afterSubmit: function() {
    window.location.href = this.getDankeUrl();
  },

  getDankeUrl: function() {
    return FidelityForm.config.redirectionUrl;
  },

  getFormValue: function(name, value) {
    value = value.toString();
    value = value.replace(/&/g, '&amp;');
    value = value.replace(/</g, '&lt;');
    value = value.replace(/>/g, '&gt;');
    value = value.replace(/"/g, '&quot;');

    return `<input type="hidden" name="${name}" value="${value}" />`;
  },

  showError: function(field) {
    field.addClass('red');
    field.closest('.checkbox-holder').addClass('red');
    jQuery('div.errorMessage').slideDown(250);
  },

  hideError: function(e) {
    let field = jQuery(e.currentTarget);
    field.removeClass('red');
    field.closest('.checkbox-holder').removeClass('red');
    jQuery('div.errorMessage').slideUp(150);
  },

  toggleOptIn: function(e) {
    let field = jQuery(e.target);
    let cb = this.f.find(`div.${field.attr('id')}_opt_in`);
    if (field.val() || e.type === 'focus') {
      cb.slideDown(250);
    } else {
      cb.slideUp(250);
    }
  },

  encodeData: function(data) {
    let p = [];
    for (let i in data) {
      p.push(`${i}=${window.escape(this.addPadding(i, this.encodeSpecialChar(data[i])))}`); // unsbedingt "escape" benutzen, weil der Server kein utf8 kann
    }
    return p.join('&');
  },
  encodeSpecialChar: function(value) {
    if (typeof value === 'string' && value.length > 0) {
      value = value
        .replace(/[aáàâåãæ]/gi, 'a')
        .replace(/[íìïî]/gi, 'i')
        .replace(/[óòôø]/gi, 'o')
        .replace(/[úùû]/gi, 'u')
        .replace(/[çc]/gi, 'c')
        .replace(/[š]/gi, 's')
        .replace(/[éèëê]/gi, 'e')

        .replace(/[ä]/g, 'ae')
        .replace(/[ö]/g, 'oe')
        .replace(/[ü]/g, 'ue')
        .replace(/[Ä]/g, 'Ae')
        .replace(/[Ö]/g, 'Oe')
        .replace(/[Ü]/g, 'Ue')
        .replace(/[ß]/gi, 'ss');
    }
    return value;
  },

  addPadding: function(key, value) {
    if (key.length < 8 && key[0] !== '$' && key !== 'vorname') {
      value = `\t${value}`;
    }

    return value;
  }
};

jQuery(function() {
  FidelityForm.init();
});

$(window).load(function() {
  let $callForm = $('#callback_form');
  if ($callForm.length) {
    $callForm.parsley().on('form:validated', function(formInstance) {
      let ok = formInstance.isValid();
      $('.message-box--error').toggleClass('page-level-error', ok);
    });
  }
});
