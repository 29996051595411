$(document).ready(function() {
  function hideMaskedOverlayForSideNav() {
    $('.masked-overlay-hide-sn').addClass('hide-masked-overlay');
    $('#body-container').addClass('remove-filter');
  }
  hideMaskedOverlayForSideNav();
});
$(window).load(function() {
  function changeFooterWidth() {
    const isLeftNavactive = $('.left-hand-nav-wrapper').length;

    if (isLeftNavactive > 0) {
      $('.fil-global-footer').addClass('left-nav-active');
    }
  }
  function changeCredBarWidth() {
    const isLeftNavactive = $('.left-hand-nav-wrapper').length > 0;

    if (isLeftNavactive) {
      $('.fil-credit-banner').addClass('left-nav-active');
    }
  }
  function addScrollBar() {
    let elementHeight = 0;
    $('.list-item-wrapper').each((index, item) => {
      elementHeight += $(item).height();
    });

    const leftMenuHeight = $('.left-nav-menu').height();

    if (elementHeight > leftMenuHeight) {
      $('.left-hand-nav-wrapper ::-webkit-scrollbar').css({ display: 'none', width: 0 });
    }
  }

  function activeURLSelector() {
    let url_matcher = '';
    let full_url = '';
    if (window.location.search.indexOf('live-url') > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      url_matcher = urlParams.get('live-url');
      full_url = window.location.pathname + window.location.search;
      full_url = full_url.replace('edit', 'preview');
    } else {
      url_matcher = $('#active-page-url').val();
    }
    $(`.left-nav-menu a[href='${url_matcher}']`).addClass('active-nav-heading');
    $(`.left-nav-menu a[href='${full_url}']`).addClass('active-nav-heading');
  }

  function activeHeaderLink() {
    $('.active-nav-heading')
      .parent('.list-item-wrapper')
      .addClass('active-menu-component');
    let check_for_active_submenu = $('.left-nav-submenu-title.active-nav-heading').length;

    if (check_for_active_submenu > 0) {
      $('.left-nav-submenu-title.active-nav-heading')
        .parent('.left-hand-nav-list-item')
        .addClass('active-link');
      $('.left-nav-submenu-title.active-nav-heading')
        .closest('.nav-item')
        .addClass('active-menu-component');
    }
  }

  changeCredBarWidth();
  changeFooterWidth();
  activeURLSelector();
  activeHeaderLink();
  addScrollBar();
});
