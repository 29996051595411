// Ensures social share buttons are always visible as the user scrolls

/* global Foundation */

export const initialiseScrollHandler = () => {
  const $shareLinks = $('.fil-article-share-links');

  if ($shareLinks.css('display') === 'none') {
    // don't attach scroll handler for mobile
    return;
  }

  const $articleBody = $('.fil-article-content');
  if ($articleBody.length == 0) {
    // exit article scroll handler if the pages are not articles.
    return;
  }
  const topBuffer = 80;
  const bottomBuffer = 80;
  const shareLinksHeight = $shareLinks.height();

  const setSocialLinksTop = () => {
    const lowestShareLinksShouldGo = Math.max($articleBody.height() - shareLinksHeight - bottomBuffer, 0);
    const scrollPastArticleTop = Math.max(window.scrollY - $articleBody.offset().top + topBuffer, 0);
    const newShareLinksTop = Math.min(scrollPastArticleTop, lowestShareLinksShouldGo);
    $shareLinks.animate({ 'top': `${newShareLinksTop}px` }, 50);
  };

  document.addEventListener('scroll',
    // decrease performance impact by throttling update
    Foundation.util.throttle(setSocialLinksTop, 50),
    true);

  setSocialLinksTop();
};
