/* global Foundation */

const SearchPaginator = require('./search_paginator.js');
const { SearchSiteConfigHandler } = require('./utils.js');
const HeaderBase = require('_fil/fil-global-frontend/header-base.js');

document.addEventListener('DOMContentLoaded', () => {
  let URL = $('#fsearch').data('searchurl');
  // Grab the initial search parameter on page load to initiate ajax after load to sort to the filter tabs
  let initialSearch = getUrlParameter('query_text');

  // Get search configs for site bound to .search-results -> data-search-configs and
  // initialize SearchSiteConfigHandler with parsed configs.
  const configHandlerInstance = new SearchSiteConfigHandler();
  configHandlerInstance.setConfigs($('.search-results').data('search-configs'));

  let pageCategory = getUrlParameter('pagecategory');
  if (pageCategory === '') {
    pageCategory = null;
  }
  // The search config settings in DjangoCMS will determine which tabs are
  // displayed on the search page. Use the existence of these tabs to infer
  // whether or not fund searching is enabled.
  const fundsEnabled = $('.tabs-title[data-identity="fund"]').length > 0;
  const wsFundsEnabled = $('.tabs-title[data-identity="dewsfunds"]').length > 0;
  const typeaHeadEnabled = $.fn.typeahead !== undefined;

  const $searchPaginator = $('[data-paginator="search"]');
  let theSearch;

  if ($searchPaginator.length) {
    theSearch = new SearchPaginator($searchPaginator, fundsEnabled, wsFundsEnabled);
    theSearch.pageCategory = pageCategory;
  }

  $.fn.extend({
    initTab: function(settings) {
      let $tabs = this;

      function select($elem) {
        $elem
          .addClass('is-active')
          .find('[role="tab"]')
          .attr('aria-selected', 'true');
      }

      function unselect($elem) {
        if ($elem) {
          $elem
            .removeClass('is-active')
            .find('[role="tab"]')
            .attr('aria-selected', 'false');
        }
      }

      return $tabs.each(function() {
        let $tab = $(this);
        $tab.prevSelected = $tab.find('.tabs-title:eq(0)');
        if (theSearch) {
          theSearch.setFilter($tab.prevSelected.data('identity'));
        }

        $(this).on('click', '.tabs-title', function() {
          unselect($tab.prevSelected);
          $tab.prevSelected = $(this);
          select($tab.prevSelected);
          if (theSearch) {
            theSearch.setFilter($tab.prevSelected.data('identity'));
          }

          if (settings && settings.onTabClicked) {
            settings.onTabClicked(this);
          }
        });
      });
    }
  });

  initialise();

  function getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  function initialise() {
    // check if you are on results page if so run an AJAX call after the initial page render to allow for sorting
    let $form = $('form.resultsPage');
    if ($form.length) {
      if (pageCategory) {
        $form.find('[name="pagecategory"]').val(pageCategory);
      }

      $form.find('[name="query_text"]').val(initialSearch);
      initiateSearch(getSearchValue());
      initTabs();
    }
  }

  function initTabs() {
    $('#search-result-tabs').initTab({
      onTabClicked: () => {
        initiateSearch();
      }
    });
  }

  if (typeaHeadEnabled) {
    //Type ahead call used on search bar and results
    $('#query_text')
      .typeahead(
        {
          hint: true,
          highlight: true,
          minLength: 1
        },
        {
          limit: 99,
          async: true,
          source: function(query, processSync, processAsync) {
            return $.ajax({
              url: URL,
              type: 'GET',
              data: {
                query_text: query,
                pagecategory: pageCategory,
                is_ajax: true
              },
              success: function(data) {
                return processAsync(data);
              }
            });
          }
        }
      )
      .on('typeahead:selected', function() {
        //Typeahead option select event capture
        $('#query_text').trigger('keyup');
      });
  }

  //Clean the input
  function getSearchValue() {
    let $form = $('form.resultsPage');
    let value;
    if ($form.length) {
      value = $form.find('[name="query_text"]').val();
    } else {
      value = $('#fsearch [name="query_text"]').val();
    }

    return $.trim(value);
  }

  //Search bar controls
  $('.topsearch .typeahead').on('keyup', function(e) {
    const value = getSearchValue();
    if (pageCategory) {
      $('#pagecategory').val(pageCategory);
    }

    if (value.length >= 3) {
      $('#search')
        .addClass('fill')
        .removeAttr('disabled');
    } else {
      $('#search')
        .removeClass('fill')
        .attr('disabled', 'disabled');
    }
    if (e.keyCode == 13 && value.length >= 3) {
      $('#fsearch').submit();
    }
  });

  $('#search').attr('disabled', 'disabled');
  $('.toggle-search').on('click', function(e) {
    Foundation.MediaQuery._init();
    let header = new HeaderBase(Foundation);
    if ($('.search-wrapper').hasClass('active')) {
      $('.yext-search-enabled .mglass').css('display', 'block');
      $('.search-wrapper')
        .find('.search-input')
        .val('');
      revealNav();
      header._makeSearchBarFocusable(false);
    } else {
      $('.yext-search-enabled .mglass').css('display', 'none');
      hideNav();
      header._makeSearchBarFocusable(true);
    }
    e.preventDefault();
  });

  $('.search-wrapper .typeahead').on('keyup', function() {
    $('#Search').attr('disabled', $(this).val().length < 3);
  });

  function hideNav() {
    if (!Foundation.MediaQuery.atLeast('large')) {
      hideNavComplete();
    } else {
      $('.tier-one-wrapper')
        .fadeOut(100, 'linear')
        .promise()
        .done(hideNavComplete);
    }
  }

  function hideNavComplete() {
    $('.search-wrapper').toggleClass('active');
  }

  function revealNav() {
    $('.search-wrapper').removeClass('active');
    if (Foundation.MediaQuery.atLeast('large')) {
      setTimeout(function() {
        $('.tier-one-wrapper').fadeIn(400, 'linear');
      }, 300);
    }
  }

  //reults page controls
  $('.topsearch #search').on('click', function() {
    const value = getSearchValue();
    if (pageCategory) {
      $('#pagecategory').val(pageCategory);
    }
    if (value.length >= 3) {
      $('#fsearch').submit();
    }
  });

  $('.resultsPage .typeahead')
    .on('keypress', function(e) {
      let keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();

        if (typeaHeadEnabled) {
          let $form = $('form.resultsPage');
          $form.find('.typeahead').typeahead('close');
        }

        return false;
      }
    })
    .on('keyup', function(e) {
      let value = getSearchValue();
      if (e.keyCode == 13 && value.length >= 3) {
        //Clear the reult containers for the ajax results
        initiateSearch(value);
      }
    });

  $('#Search').click(evt => {
    evt.preventDefault();
    const value = getSearchValue();
    initiateSearch(value);
  });

  //AJAX to request search reults
  function initiateSearch(value) {
    const searchTerm = value || getSearchValue();
    if (theSearch) {
      theSearch.search(searchTerm);
    }
  }

  //display and hide panes
  $('#tabList li').click(function() {
    let id = $(this).data('identity');
    $(this)
      .closest('ul')
      .find('a')
      .removeClass('isActive');
    $(this)
      .find('a')
      .addClass('isActive');
    $('.resultItem').hide();
    $(`#${id}`).show();
  });

  $('#search-result-tabs').on('click', 'li', function() {
    let data_identity = $(this).data('identity');
    let search_val = $('#query_text').val();
    let help_support_url = 'https://help.fidelity.co.uk/site/search?nlpq=';
    if (data_identity === 'helpsupport') {
      $('.tabs-title[data-identity="helpsupport"] a').attr(
        'href',
        `${help_support_url}${search_val}`
      );
      $('.tabs-content').hide();
    } else {
      $('.tabs-content').show();
    }
  });
});
