export const watchListConfigKeys = {
  validationMsg:
    '<p>You\'ve reached the limit of 50 investments.<br><a href=\'/secure/accounts#watchlist\'>Go to your Watchlist</a> and remove some investments before trying again.</p>',
  WatchlistAjaxURL: '/gateway/ei/assetwatchlist/v1/watchlist',
  addedLabel: 'View your Watchlist',
  addedLink: '/secure/accounts#watchlist',
  heading: 'The Investment has been added to your Watchlist',
  messageBodyHeading: '<p>With your Watchlist , you can  :</p>',
  messageBodyContent:
    '<ul><li> Save a list of investments you\'re  interested in</li><li> Track how well those investments are doing </li><li>Invest when you\'re ready to do so </li></ul>',
  watchListFullHeading: '    Your Watchlist is full',
  watchListFullMessageBodyHeading:
    '<p class = "message-Body-heading">We are unable to add the investment as your Watchlist is full. Please remove some investments from your Watchlist before trying again.</p>',
  headingIconAddition:
    '<span class="icon icon-green"><span class="fil-icon icon-only fil-icon-confirm">',
  headingIconWarning: '<span class="fil-icon icon-only fil-icon-warning"></span>',
  watchListErrorHeading: '     Unable to add investment',
  watchListBodyErrorHeading:
    '<p class = "message-Body-heading">There was an issue adding the investment to your Watchlist. Please try again. If the issue persists, please <a href=\'https://sit.fidelity.co.uk/contact/\'>contact us.</a></p>',
  closeBtn: 'close',
  watchlistAddButton:
    ' <div class="controls"><a class="button viewYourWatchList" href=\'/secure/accounts#watchlist\'>View your Watchlist</a></div>',
  alreadyAddedFundHeading: 'This Investment has been already added to your Watchlist'
};
