$(document).ready(function() {
  /**
   * @description - Multi drop down onClick functions
   */
  $('.dropdown-main-item').on('click touch', function() {
    $('.fil-multi-dropdown-submenu.first-sub').toggleClass('dropdown-active');
    $('.fil-multi-dropdown-parent-element').toggleClass('dropdown-open');

    if (dropdownStates.mainMenuItem) {
      dropdownStates.mainMenuItem.css('height', '').removeClass('is-sub-opened');
    }

    if (dropdownStates.parent && dropdownStates.child) {
      dropdownStates.child.removeClass('dropdown-active');
      dropdownStates.parent
        .removeClass('cur-active-sub-level')
        .find('.fil-icon')
        .addClass('fil-icon-chevron-right-s')
        .removeClass('fil-icon-chevron-left-s');
    }

    dropdownStates.isSubMenuOpened = false;
  });

  $('.fil-multi-dropdown .backdrop').on('click touch', function() {
    $('.dropdown-main-item').click();
  });

  let dropdownStates = {
    parent: null,
    child: null,
    isSubMenuOpened: false,
    mainMenuItem: null
  };

  $('.dropdown-submenu-item').on('click touch', function() {
    let curSubMenuUl = $(`.fil-multi-dropdown-innermenu-${$(this).attr('data-value')}`);
    let lastVisitSubMenu = dropdownStates.child;
    let mainMenuItem = (dropdownStates.mainMenuItem = $(this).parents('.first-sub'));

    if (lastVisitSubMenu) {
      if (lastVisitSubMenu[0] == curSubMenuUl[0]) {
        lastVisitSubMenu.toggleClass('dropdown-active', !dropdownStates.isSubMenuOpened);

        dropdownStates.parent
          .toggleClass('cur-active-sub-level', !dropdownStates.isSubMenuOpened)
          .find('.fil-icon')
          .toggleClass('fil-icon-chevron-right-s', dropdownStates.isSubMenuOpened)
          .toggleClass('fil-icon-chevron-left-s', !dropdownStates.isSubMenuOpened);

        dropdownStates.isSubMenuOpened = !dropdownStates.isSubMenuOpened;
      } else {
        lastVisitSubMenu.removeClass('dropdown-active');
        dropdownStates.parent
          .removeClass('cur-active-sub-level')
          .find('.fil-icon')
          .addClass('fil-icon-chevron-right-s')
          .removeClass('fil-icon-chevron-left-s');

        curSubMenuUl.addClass('dropdown-active');
        $(this)
          .addClass('cur-active-sub-level')
          .find('.fil-icon')
          .removeClass('fil-icon-chevron-right-s')
          .addClass('fil-icon-chevron-left-s');

        dropdownStates.parent = $(this);
        dropdownStates.child = curSubMenuUl;
        dropdownStates.isSubMenuOpened = true;
      }
    } else {
      curSubMenuUl.addClass('dropdown-active');
      $(this)
        .addClass('cur-active-sub-level')
        .find('.fil-icon')
        .removeClass('fil-icon-chevron-right-s')
        .addClass('fil-icon-chevron-left-s');

      dropdownStates.parent = $(this);
      dropdownStates.child = curSubMenuUl;
      dropdownStates.isSubMenuOpened = true;
    }

    mainMenuItem.css('height', '');
    curSubMenuUl.css('height', '');
    if (dropdownStates.isSubMenuOpened) {
      let leftMenuHeight = mainMenuItem.height();
      let rightMenuHeight = curSubMenuUl.height();

      if (leftMenuHeight < rightMenuHeight) {
        mainMenuItem.height(rightMenuHeight);
      } else {
        curSubMenuUl.height(leftMenuHeight);
      }
    }

    mainMenuItem.toggleClass('is-sub-opened', dropdownStates.isSubMenuOpened);
  });

  /**
   * @description - Site selector handling to create/set userCategory cookie (uk site ONLY)
   */
  $('.sub-menu a').on('click', function() {
    const switchSite = $(this)
      .attr('href')
      .split('?')[0];
    if (switchSite === '/' || switchSite === '/home' || switchSite === '/home/') {
      Cookie.set('userCategory', 'Client');
    } else if (
      switchSite === '/clients' ||
      switchSite === '/clients/' ||
      switchSite === '/clients/home' ||
      switchSite === '/clients/home/'
    ) {
      Cookie.set('userCategory', 'BrokerClient');
    }
  });

  myFavoriteLink.init();
});

// TODO: replace with js-cookie
let Cookie = {
  set: function(name, value, seconds) {
    let expiry = '';
    if (seconds) {
      let d = new Date();
      d.setTime(d.getTime() + seconds * 1000);
      expiry = `; expires=${d.toGMTString()}`;
    }
    document.cookie = `${name}=${value}${expiry}; domain=.fidelity.co.uk; path=/; secure`;
  },
  get: function(name) {
    let nameEQ = `${name}=`;
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return '';
  }
};

let myFavoriteLink = {
  init() {
    this.initContext();
    this.bindListeners();
    this.detectMyFavoriteList();
  },

  initContext() {
    this.myFavoriteCookieID = 'associationIdList';
    this.maxMyFavoriteNum = 20;
    this.$links = $('.wfb-nav .link');
  },

  bindListeners() {
    //trigger: headerIframe.postMessage({target: 'my-favorite-link', event: 'refresh', payload: {}})
    const that = this;
    window.addEventListener('message', event => {
      const data = event.data;
      if (data && data.target === 'my-favorite-link') {
        that.handleEvents(data.event, data.payload);
      }
    });

    //trigger:$("body").trigger("my-favorite-link-event", {event: 'refresh', payload: {}});
    $('body').on('my-favorite-link-event', (event, data) => {
      that.handleEvents(data.event, data.payload);
    });
  },

  handleEvents(event) {
    if (event === 'refresh') {
      this.detectMyFavoriteList();
    }
  },

  detectMyFavoriteList() {
    const strMyFavoriteList = decodeURIComponent(Cookie.get(this.myFavoriteCookieID));
    let myFavoriteList = [];

    if (strMyFavoriteList) {
      myFavoriteList = JSON.parse(strMyFavoriteList);
    }

    this.$links
      .filter((id, item) => {
        return /my-?favou*rite-?list/i.test(item.href);
      })
      .toggleClass('disabled', !myFavoriteList.length)
      .text((_, oldLabel) => {
        oldLabel = oldLabel.replace(/\s*\([^)]*\)\s*/g, '');
        return `${oldLabel} (${myFavoriteList.length}/${this.maxMyFavoriteNum}件)`;
      });
  }
};
