/* global KV */

class VideoWidget {
  constructor({ widgetSelector, qumuId }) {
    this.widget_selector = widgetSelector;
    this.qumu_guid = qumuId;
    this.rendered = false;

    this.initConfig();
  }

  /**
   * Init the widget config
   */
  initConfig() {
    this.config = {
      width: 332,
      height: 221
    };
  }

  /**
   * render the widget using Qumu API
   */
  render() {
    const config = this.config;
    const widget = (this.widget = KV.widget({
      selector: this.widget_selector,
      type: 'thumbnail',
      playerType: 'full',
      guid: this.qumu_guid,
      showInfoPanel: false,
      hideEmbedButton: true,
      hideDownloadButton: true,
      autoPlay: true,
      // Set globally in the video_gallery.html template
      // addons-dev\global-cms-video-gallery\global_cms_video_gallery\templates\global_cms_video_gallery\video_gallery.html
      // TODO: move this value to a data tag attribute
      defaultThumbnail: window.videoGalleryNoVideoImage,
      emptyMessage: '',
      packshot: {
        width: config.width,
        height: config.height,
        scale: true,
        fit: 'stretch'
      }
    }));

    this.widget.closest('.card').on('click', '.play-trigger', function() {
      widget.find('.kv-packshot').click();
    });

    const api = this.widget.api;
    api.init(() => {
      api.get('kulus', kulus => {
        if (kulus.length == 0) {
          this.onVideoLoadError();
        }
      });
    });

    this.rendered = true;
  }

  /**
   * get called when video loading failed.
   */
  onVideoLoadError() {
    this.widget.addClass('thumbnail-empty');
    this.widget.css('cssText', `height: ${this.config.height}px !important`);
    this.widget
      .parent()
      .find('>.play-button')
      .hide();
  }
}

module.exports = VideoWidget;
