const { watchListConfigKeys } = require('./watchListConfig');
const Fuse = require('./fuse.min.js');
const { SearchSiteConfigHandler } = require('./utils.js');
const Paginator = require('_fil/fil-global-frontend/paginator');
const { getLoginState } = require('_fil/fil-global-frontend/login');
const AUTOSCROLL_SPEED = 1000;
const FUND_FINDER_URL = 'https://cf.fww.de/fidelity/json-feeds/fondsfinder-mini-with-etfs.json';
const DE_WS_FUND_FINDER_URL =
  'https://partner.fidelity.de/api/ce/fdh/FundList.json?countries=de&country=de&languages=de%2Cen&language=de&channels=ce.professional-investor&channel=ce.professional-investor';
const DE_FUND_FACTSHEET_URL =
  '/produkte-services/fonds-verschiedener-anbieter/fondsfinder/fondsportrait/?ISIN=';
let DE_WS_FUND_FACTSHEET_URL = 'https://partner.fidelity.de/fonds/factsheet/';
import { getWatchlist, userWlistNewArray } from './watchlist_integration';

/**
 * Search Results
 *
 * This class handles the retrieval, filtering, display and pagination of search
 * results from both the Fund Finder API and the CMS ElasticSearch.
 *
 * fundsEnabled argument determines whether to include the fund searching from
 * the Fund Finder service.
 */
class SearchPaginator extends Paginator {
  constructor(rootEl, fundsEnabled, wsFundsEnabled) {
    super(rootEl);

    this.allFunds = [];
    this.fundResults = [];
    this.wsFundresults = [];
    this.cmsResults = [];

    this.fundsEnabled = fundsEnabled;
    this.wsFundsEnabled = wsFundsEnabled;
    this.filter = '__all__';
    this.lastSearch = undefined;

    this.$resultTemplate = this.$root.find('.search-result.search__template');

    this.configHandlerInstance = new SearchSiteConfigHandler();
    this.isUKFundsDealEnabled =
      this.configHandlerInstance.getForKey('UKFUNDS_DEAL_ENABLED').toLowerCase() === 'true';
    this.isUKFundsWatchlistEnabled =
      this.configHandlerInstance.getForKey('UKFUNDS_WATCHLIST_ENABLED').toLowerCase() === 'true';
    this.isUKHelpSupportTabEnabled =
      this.configHandlerInstance.getForKey('UK_HELP_SUPPORT_TAB_ENABLED').toLowerCase() === 'true';
    this.isUserLoggedInForSearchBind = false;
    getLoginState().then(loginresult => {
      this.isUserLoggedInForSearchBind = loginresult;
      this.rebuild();
    });
    getWatchlist(watchListConfigKeys.WatchlistAjaxURL).then(() => this.rebuild());

    this.appendHelpandSupportTab();
  }

  /**
   * Generate the full set of results by joining the fund and CMS results,
   * filtering by type if necessary. Funds have priority and display first.
   * @return {array} List of result objects
   */

  appendHelpandSupportTab() {
    const $HELP_AND_SUPPORT = `<li data-identity='helpsupport' class='tabs-title' id='help_and_support'>
                                <a href='javascript:void(0)' aria-selected='false' role='tab'>Help & support</a>
                            </li>`;
    if (this.isUKHelpSupportTabEnabled && $('#help_and_support').length < 1) {
      $('#search-result-tabs').append($HELP_AND_SUPPORT);
    }
  }

  getResults() {
    switch (this.filter) {
      case '__all__':
        return this.fundResults.concat(this.wsFundResults).concat(this.cmsResults);
      case 'fund':
        return this.fundResults;
      case 'dewsfunds':
        return this.wsFundResults;
      default:
        return this.cmsResults.filter(res => res.type === this.filter);
    }
  }

  /**
    This is the return for the content that needs to be displayed in the search result template
  */
  buildExcerptFromUKFund(result) {
    const wishlist = userWlistNewArray();
    const isFundAddedToWatchList = wishlist.includes(result.isin);
    const watchlistFundAdded = isFundAddedToWatchList ? 'arrow-right-cta' : '';
    const watchlistLabel = isFundAddedToWatchList
      ? watchListConfigKeys.addedLabel
      : 'Add to watchlist';
    const watchlistHref = isFundAddedToWatchList
      ? watchListConfigKeys.addedLink
      : 'javascript:void(0)';

    const deal_url = `"javascript:openDealLayer( '${
      result.isin
    }','Global Search_Off Platform Fund Searched_No' )"`;

    const dealLink = this.isUKFundsDealEnabled
      ? `<a class="search-result__deal-link" href=${deal_url}>Deal</a>`
      : '';

    const watchlistLink =
      this.isUserLoggedInForSearchBind && this.isUKFundsWatchlistEnabled
        ? `<a
            class="search-result__watchlist-link linkWatchlist ${watchlistFundAdded}"
            fundName="${result.title}"
            href="${watchlistHref}"
            id="wlist${result.isin}"
            isin=${result.isin}
            sedol=${result.sedol}
          >${watchlistLabel}</a>`
        : '';

    return `
      <a class="search-result__isin-link" href="${result.url}">
        ${result.isin}
      </a>
      ${result.content}
      <p class="search-result__link-wrapper">
        ${dealLink}
        <a class="search-result__factsheet-link" href="${result.url}">View Factsheet</a>
        ${watchlistLink}
      </p>
    `;
  }

  buildExcerptFromDewsFund(result) {
    let targetStr = `WKN: ${result.shareClassFacts.wkn}&nbsp;&nbsp;&nbsp;&nbsp;ISIN: ${
      result.shareClassFacts.isin
    }`;
    if ($(window).outerWidth() <= 640) {
      targetStr +=
        '<div class="search-result__excerpt--inner"><span class="pro-badge">PRO</span><span class="fil-icon fil-icon-info-circle-14"></div>';
    } else {
      targetStr += '<span class="pro-badge">PRO</span>';
    }
    return targetStr;
  }

  /**
   * Retrieve the results for a particular page.
   *
   * @return {array} Array of results in a format understood by buildResult()
   */
  getResultsForPage(page) {
    const results = this.getResults();
    const start = page * this.perPage;
    const end = (page + 1) * this.perPage;
    return results.slice(start, end);
  }

  /**
   * Calculate the maximum number of pages for the current search result
   */
  getMaxPages() {
    const pages = Math.ceil(this.getResults().length / this.perPage);
    return Math.max(0, pages);
  }

  /**
   * Retrieve the total number of results
   *
   * @return {number} Total results
   */
  getTotalResults() {
    return this.getResults().length;
  }

  /**
   * Scroll smoothly to the top of the screen
   */
  onPageChange() {
    $('html, body').animate(
      {
        scrollTop: 0
      },
      AUTOSCROLL_SPEED
    );
  }

  /**
   * Set the filter.
   *
   * If the filter has changed, set the page back to 0 and rebuild the view.
   * @param {string} filter String to filter by
   */
  setFilter(filter) {
    if (this.filter !== filter) {
      this.filter = filter;
      this.page = 0;
      this.rebuild();
    }
  }

  /**
   * Build the DOM for a single result
   * @param {object} result Data for a single result
   * @return {DOM Element}  DOM element representing the result
   */
  buildResult(result) {
    // Clone and append template
    const el = this.$resultTemplate
      .clone()
      .removeClass('search__template')
      .addClass(`search-result--${result.type}`);

    let excerpt, title, url;
    switch (result.type) {
      case 'de_fund':
        title = result.FN;
        url = DE_FUND_FACTSHEET_URL + result.ISIN;
        excerpt = `WKN: ${result.WKN}&nbsp;&nbsp;&nbsp;&nbsp;ISIN: ${result.ISIN}`;
        break;
      case 'dews_fund':
        title = result.displayName;
        url = `${DE_WS_FUND_FACTSHEET_URL}${result.shareClassFacts.isin}`;
        excerpt = this.buildExcerptFromDewsFund(result);
        break;
      case 'ukfunds':
        title = result.title;
        url = result.url;
        excerpt = this.buildExcerptFromUKFund(result);
        break;
      default:
        excerpt = result.content;
        title = result.title;
        url = result.url;
        break;
    }

    el.find('.search-result__excerpt').html(excerpt);
    el.find('.search-result__link')
      .attr('href', url)
      .text(title);

    return el;
  }

  /**
   * Download all of the funds from the Fund Finder API and store the records
   * in the allFunds attribute.
   */
  downloadAllFunds() {
    const dfd = $.Deferred();

    if (this.allFunds.length) {
      dfd.resolve(this.allFunds);
    } else {
      $.ajax({
        dataType: 'jsonp',
        url: FUND_FINDER_URL,
        jsonpCallback: '_fondsSearchMinData',
        type: 'GET',
        success: response => {
          response.forEach(fund => (fund.type = 'de_fund'));
          this.allFunds = response;
        },
        error: (jqXHR, textStatus, errorThrown) => {
          console.error(textStatus, errorThrown);
          this.allFunds = [];
        },
        complete: () => {
          dfd.resolve(this.allFunds);
        }
      });
    }

    return dfd.promise();
  }
  downloadWsFunds() {
    const dfd = $.Deferred();

    if (this.allFunds.length) {
      dfd.resolve(this.allWSFunds);
    } else {
      $.ajax({
        url: DE_WS_FUND_FINDER_URL,
        type: 'GET',
        success: response => {
          response = Object.values(response);
          response.forEach(fund => (fund.type = 'dews_fund'));
          this.allWSFunds = response;
        },
        error: (jqXHR, textStatus, errorThrown) => {
          console.error(textStatus, errorThrown);
          this.allWSFunds = [];
        },
        complete: () => {
          dfd.resolve(this.allWSFunds);
        }
      });
    }

    return dfd.promise();
  }
  /**
   * Displays the loading spinner
   */
  addSpinner() {
    $('#search-results__spinner-container').show();
  }

  /**
   * Removes the loading spinner
   */
  removeSpinner() {
    $('#search-results__spinner-container').hide();
  }

  /**
   * Initiate a new search
   */
  search(term) {
    if (this.lastSearch !== term) {
      this.lastSearch = term;
      this.page = 0;
      this.addSpinner();
      $.when(this.searchCMS(term), this.searchDEFunds(term), this.searchDEWSFunds(term)).done(
        (cmsResults, fundResults, wsFundResults) => {
          this.cmsResults = cmsResults;
          this.fundResults = fundResults;
          this.wsFundResults = wsFundResults;
          this.rebuild();
          this.removeSpinner();
        }
      );
    }
  }

  /**
   * Call the DjangoCMS ElasticSearch API to query for CMS content
   * @param  {string} term Term to filter by
   */
  searchCMS(term) {
    const dfd = $.Deferred();
    const searchurl = $('#fsearch').attr('action') || '/search/all';
    $.ajax({
      url: searchurl,
      type: 'GET',
      data: {
        query_text: term,
        pagecategory: this.pageCategory,
        is_ajax: true
      },
      success: response => {
        dfd.resolve(response.data);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error(textStatus, errorThrown);
        dfd.resolve([]);
      }
    });
    return dfd.promise();
  }

  /**
   * Search through the funds list. Uses Fuse.js for the client-side search.
   * http://fusejs.io/
   */
  searchDEFunds(term) {
    const dfd = $.Deferred();
    if (this.fundsEnabled) {
      const options = {
        keys: ['FN', 'ISIN', 'WKN'],
        threshold: 0.25
      };
      $.when(this.downloadAllFunds()).done(allFunds => {
        const fuse = new Fuse(allFunds, options);
        dfd.resolve(fuse.search(term));
      });
    } else {
      dfd.resolve([]);
    }
    return dfd.promise();
  }

  searchDEWSFunds(term) {
    const dfd = $.Deferred();
    if (this.wsFundsEnabled) {
      const options = {
        keys: ['displayName', 'shareClassFacts.isin', 'shareClassFacts.wkn'],
        threshold: 0.25
      };
      $.when(this.downloadWsFunds()).done(allWSFunds => {
        const fuse = new Fuse(allWSFunds, options);
        dfd.resolve(fuse.search(term));
      });
    } else {
      dfd.resolve([]);
    }
    return dfd.promise();
  }
}

module.exports = SearchPaginator;
