$(document).ready(function() {
  if ($('#article-content').length > 0) {
    //code to fetch the category name from the path
    let cat_name = window.location.pathname;
    cat_name = cat_name.split('/');
    let len = cat_name.length;
    cat_name = cat_name[len - 3].replace(/-/g, ' ');
    const arr = cat_name.split(' ');
    arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
    cat_name = arr.join(' ');
    document.getElementById('article-content').innerHTML = cat_name;

    //code to fetch the path for the category
    let categoryName = '';
    let path_name = window.location.pathname;
    path_name = path_name.split('/');
    for (let i = 1; i < path_name.length - 2; i++) {
      categoryName += '/';
      categoryName += path_name[i];
    }
    categoryName += '/';
    document.getElementById('category-url').setAttribute('href', categoryName);
  }
});
