// Allows blockquotes to be properly styled

export const extractCaption = blockquoteText => {
  blockquoteText = blockquoteText.replace(/"|“|”|<i>|<\/i>/g, '');
  let captionIndex = blockquoteText.lastIndexOf('<b>');

  let mainQuote = blockquoteText;
  let caption = '';

  if (captionIndex > -1) {
    mainQuote = blockquoteText.substring(0, captionIndex).trim();
    caption = `<p class='fil-quote-caption small'>${blockquoteText.substring(captionIndex).replace(/<b>|<\/b>/g, '').trim()}</p>`;
  }

  return `<p class='fil-quote-text'>“${mainQuote}”</p>${caption}`;
};

export const formatBlockQuotes = () => {
  $('.article-body blockquote').each((i, el) => {
    el.innerHTML = extractCaption(el.innerHTML);
  });
};