const filCustomEvent = require('_fil/fil-global-frontend/trigger-analytics');

/* global Foundation */

$(document).ready(function() {
  /**
   * @description - Glossary functions
   */

  if (Foundation.MediaQuery.atLeast('medium')) {
    $('.glossary-nav-container .fil-showhide__button').addClass('is-hidden');
  }

  $('.fil-showhide__button').click(function() {
    $(this).hasClass('is-active')
      ? $(this).removeClass('is-active')
      : $(this).addClass('is-active');
  });

  $('.glossary-nav-container').on('sticky.zf.stuckto:top', function() {
    let el = $('.glossary-nav-container .accordion');
    let pane = el.find('.accordion-content');
    el.foundation('up', pane);
    $('.glossary-nav-container .fil-showhide__button').removeClass('is-hidden');
  });

  $('.glossary-nav-container').on('sticky.zf.unstuckfrom:top', function() {
    if (Foundation.MediaQuery.atLeast('medium')) {
      let el = $('.glossary-nav-container .accordion');
      let pane = el.find('.accordion-content');
      el.foundation('down', pane, false);
      $('.glossary-nav-container .fil-showhide__button').addClass('is-hidden');
    }
  });

  $('.fil-glossary .fil-icon-container').on('click', function() {
    let title_name = $(this)
      .closest('.fil-glossary')
      .text()
      .trim()
      .split('\n');
    $(this)
      .closest('.accordion-item')
      .hasClass('is-active')
      ? ''
      : filCustomEvent.callAnalytics(
        'event',
        'global.interactionEvent',
        'accordion',
        'accordion expand',
        title_name[0]
      );
  });

  $('.glossary-navigation_btn-inner').on('click', function() {
    let letter_selected = $(this).text();
    filCustomEvent.callAnalytics(
      'event',
      'global.interactionEvent',
      'jargon buster',
      'letter selected',
      letter_selected
    );
  });
});
