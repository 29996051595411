const ArticlePaginator = require('./article_paginator');
require('./article_detail_layout');
$(document).ready(function() {
  // Initialise the ArticlePaginator for each paginator in the page. The first
  // paginator takes priority for handling SEO and push state.
  $('[data-paginator="article"]').each((i, el) => {
    const paginator = new ArticlePaginator(el, {
      disableSEO: i > 0,
      disableURL: i > 0
    });
    paginator.rebuild();
  });
});
