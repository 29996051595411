const filCustomEvent = require('_fil/fil-global-frontend/trigger-analytics');

$(document).ready(function() {
  $('.accordion-title').on('click', function() {
    let question_name = $(this)
      .text()
      .trim();
    $(this)
      .closest('.accordion-item')
      .hasClass('is-active')
      ? filCustomEvent.callAnalytics(
        'event',
        'global.interactionEvent',
        'accordion',
        'accordion expand',
        question_name
      )
      : '';
  });
});
