//RNG parameter generator to stop caching searches
function rng(randLength) {
  let num = Math.floor(1 + Math.random() * Math.pow(randLength, randLength));
  return num;
}

class SearchSiteConfigHandler {
  constructor() {
    if (!SearchSiteConfigHandler._instance) {
      SearchSiteConfigHandler._instance = this;
    }
    return SearchSiteConfigHandler._instance;
  }

  setConfigs(configs) {
    this._configs = configs || {};
  }

  getForKey(key) {
    return this._configs[key] ? this._configs[key].trim() : '';
  }
}

module.exports = {
  rng: rng,
  SearchSiteConfigHandler: SearchSiteConfigHandler
};
