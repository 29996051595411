const Paginator = require('_fil/fil-global-frontend/paginator');
const VideoWidget = require('./video_widget');
const AUTOSCROLL_SPEED = 1000;

class VideoPaginator extends Paginator {

  constructor (rootEl, opts={}) {
    super(rootEl, opts);
    // Immediately select and cache the videos
    this.$videos = this.$results.find('.fil-video-gallery__item');
    this.videoType = this.$root.data('video-type') || 'qumu';
    this.videoWidgetPool = [];

    this.initVideoList();
    this.updateStateFromUrl();
  }

  /**
   * Init the video widget instances, and add to pool
   * page
   */
  initVideoList() {
    if (this.isQumu()) {
      this.$videos.each((i, el) => {
        const $el = $(el);
        const instanceId = $el.data('instance-id');
        const widgetSelector = `#widget-${instanceId}`;
        const qumuId = $el.data('qumu-guid');

        this.videoWidgetPool.push( new VideoWidget({widgetSelector, qumuId}) );
      });
    }
  }

  /**
   * check whether current video type is qumu (not youtube)
   */
  isQumu() {
    return this.videoType === 'qumu';
  }


  /**
   * Retrieve the lower and upper bounds of result indices required for this
   * page
   *
   * @override
   * @param  {number} page Page number to retrieve values for
   * @return {[number, number]} Minimum and maximum result index to display
   */
  getResultsForPage (page) {
    return [
      page * this.perPage,
      ((page + 1) * this.perPage) - 1,
    ];
  }


  /**
   * Count the number of result elements
   *
   * @override
   * @return {number} Total results
   */
  getTotalResults () {
    return this.$videos.length;
  }

  /**
   * Scroll smoothly to the top of the video list when states change
   */
  onStateChange() {
    const videoPos = Math.round(this.$root.offset().top);
    $('html, body').animate({ scrollTop: videoPos }, AUTOSCROLL_SPEED);
  }

  /**
   * Iterate through the result DOM elements and conditionally display the
   * elements based on the provided index bounds.
   *
   * if the video type is qumu, will render the qumu video widgets
   *
   * @override
   * @param {[number, number]} bounds Minimum and maximum index for display
   */
  buildResults (bounds) {
    const [min, max] = bounds;

    this.$videos.each((i, el) => {
      const visible = min <= i && i <= max;

      if (this.isQumu() && visible) {
        const widget = this.videoWidgetPool[i];
        if (!widget.rendered) {
          widget.render();
        }
      }

      $(el).toggleClass('hide', !visible);
    });
  }
}

module.exports = VideoPaginator;
